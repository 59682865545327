<template>
	<div class="flex-column full-height bg-white" ref="body">

		<Top
			:program="program"
			:member_info="member_info"
			:seller_info="seller_info"
			:mall_info="mall_info"

		></Top>
		<Search
			v-if="program.search"
			:program="program"
			:search="search"

			@click="toList"
		></Search>
		
		<div
			v-if="banner_item.uid && is_banner && items_banner_main.length > 0"
			class="box-main-banner position-relative"
		>
			<v-icon
				class="position-absolute arrow-left"
				@click="banner_prev"
			>mdi mdi-chevron-left</v-icon>
			<ul
				class="ul-main-banner"
			>
				<template
					v-if="is_banner && items_banner_main.length > 0"
				>
				<li
					v-for="(banner, banner_index) in banner_main"
					v-show="banner_item.uid == banner.uid"
					:key="'banner_' + banner_index"
					@click="toLink(banner)"
				>
					<transition
						enter-class="enter"
					>
					<div
						class="banner-img"
						:style="'background: url(' + banner.banner_img + ') no-repeat center center; background-size: 100%'"
					>
					</div>
					</transition>
				</li>
				</template>
				<li
					v-else
					class="no-banner"
				>no banner</li>
			</ul>
			<v-icon
				class="position-absolute arrow-right"
				@click="banner_next"
			>mdi mdi-chevron-right</v-icon>
		</div>

		<div
			class="position-relative"
		>
			<div class="text-center pa-20 size-px-14 font-weight-700"># 브랜드</div>
			<div
				v-if="is_banner && items_banner_category.length > 0"
				class="pa-10-20 justify-start flex-scroll gap-20"
			>
				<div
					v-for="(banner, banner_index) in banner_category"
					:key="'banner_' + banner_index"
					@click="toCategory(banner)"
				>
					<span
						class="bg-category flex-column justify-center items-center"
					>
						<img :src="banner.banner_img" class=""/>
					</span>
					<div class="size-px-10 text-center" style="letter-spacing: -2px"> {{ banner.category_name }}</div>
				</div>
			</div>
		</div>

		<div
			v-if="false"
			class="pa-10 justify-space-between "
		>
			<select
				v-model="search.sort"
				class="pa-5 box bg-white"
				@change="setSort"
			>
				<option
					v-for="sort in codes.P002.items"
					:key="'sort_' + sort.sub_code"
					:value="sort.code_value ? sort.code_value : ''"
				>{{ sort.code_name }}</option>
			</select>

			<span>
				<v-icon
					class="box pa-5 mr-5 "
					:class="list_type == 'list' ? 'bg-gray' : 'bg-white'"
					@click="setListType('list')"
				>mdi mdi-view-list</v-icon>
				<v-icon
					class="box pa-5 "
					:class="list_type == 'grid' ? 'bg-gray' : 'bg-white'"
					@click="setListType('grid')"
				>mdi mdi-view-grid</v-icon>
			</span>
		</div>

		<div
			class="mt-10 pa-20 text-center"
		>
			<button
				class="btn-primary bg-base pa-10-50 radius-20"
				@click="toList"
			>상품권 구매하기 →</button>
		</div>

		<template
			v-if="items.length > 0"
		>
		<div class="mt-10 flex-column">
			<ul
				class=" main-pdt overflow-y-auto"
				:class="list_type"
			>
				<li
					v-for="item in item_list"
					:key="item.uid"
					class="main-box-pdt position-relative"

					@click="toDetail(item)"
				>
					<div class="pdt-img">
						<img v-if="item.pdt_img"
							:src="item.pdt_img"
						/>
						<v-icon
							v-else
							class="mdi mdi-image none-img"
						></v-icon>
					</div>
					<div class="pdt-info pa-10">
						<div class="pdt-title color-333">{{  item.pdt_name }}</div>

						<template
							v-if="true"
						>
							<div
								v-if="item.is_sale"
								class="price font-weight-bold size-px-14"
							>{{ item.pdt_price | makeComma }} 원</div>
							<div
								v-else
								class="price font-weight-bold justify-space-between"
							>
								<span class="text-through color-gray">{{ item.pdt_price | makeComma }} 원</span>
								<span class="mr-5 color-red">품절</span>
							</div>
						</template>
						<div
							v-else
							class="price font-weight-bold"
						>
							<span class="mr-5 color-red">회원 공개</span>
						</div>

						<div
							v-if="list_type == 'list'"
							class="color-blue "
							style="right: 10px; top: -10px;"
						>
							<template
								v-if="item.agency_pdt_type"
							>
						<span
							v-if="item.agency_pdt_type.indexOf('new') > -1"
							class="label label-new mr-5"
						>NEW</span>
								<span
									v-if="item.agency_pdt_type.indexOf('hot') > -1"
									class="label label-hot mr-5"
								>HOT</span>
								<span
									v-if="item.agency_pdt_type.indexOf('recomm') > -1"
									class="label label-recomm mr-5"
								>추천</span>
								<span
									v-if="item.agency_pdt_type.indexOf('season') > -1"
									class="label label-season"
								>계절</span>
							</template>

						</div>
					</div>

				</li>
			</ul>

			<Pagination
				:program="program"
				:align="'center'"
				:options="search"
				type="more"

				@click="getData"

				class="pa-10"
			></Pagination>
		</div>
		</template>
		<div v-else class="text-center pa-50">등록된 상품이 없습니다</div>

		<ProductDetail
			v-if="item.uid"
			:item="item"
			:Axios="Axios"
			:cart_cnt="cart_cnt"

			@click="clear"
			@onLoad="setProgram"
			@setNotify="setNotify"
			@getCart="$emit('getCart')"
			@push="$emit('push', 'Cart')"
			@getCartList="$emit('getCartList')"
		></ProductDetail>

		<More
			v-if="$refs.body"
			:body="$refs.body"
		></More>

		<PopupConfirm
			v-if="mall_info.is_notice && is_notice"

			:not_button="true"

			@click="closePop"
			@cancel="closePop"
		>
			<template
				v-slot:title
			>
				안내
			</template>
			<template
				v-slot:main-txt
			>
				{{ mall_info.notice }}<br/><br/><br/><br/>
			</template>
			<template
				v-slot:sub-txt
			>
				<button
					@click="is_not_today = !is_not_today"
				>
					<v-icon v-if="is_not_today" class="color-success">mdi mdi-checkbox-marked</v-icon>
					<v-icon v-else>mdi mdi-checkbox-blank</v-icon>
					오늘하루 그만보기
				</button>
			</template>
		</PopupConfirm>
	</div>
</template>

<script>

	import ProductDetail from "../Product/ProductDetail";
	import Pagination from "@/components/Pagination";
	import Search from "@/view/Layout/Search";
	import Top from "../Layout/Top";
	import More from "../Layout/More";
	import PopupConfirm from "../Layout/PopupConfirm";

	export default{
		name: 'Main'
		, components: {PopupConfirm, More, Top, Search, Pagination, ProductDetail}
		, props: ['Axios', 'cart_cnt', 'codes', 'TOKEN', 'filter', 'seller_info', 'member_info', 'items_banner_main', 'items_banner_category', 'is_member', 'mall_info']
		,data: function(){
			return {
				program: {
					name: process.env.VUE_APP_TITLE_DEV
					,title: false
					,search: true
					,bottom: true
				}
				,banner_item: {
					uid: ''
				}
				,banner_index: 0
				,items: []
				,item: {}
				,search: this.$storage.init({
					sort: 'low'
					,list_cnt: 10
					,page: 1
					,search_type: 'pdt_name'
				})
				,list_type: localStorage.getItem('list_type') ? localStorage.getItem('list_type') : 'grid'
				,slide_option: {
					perPage		: 1
					,arrows 	: false
					,autoplay   : false
					,start		: 0
					,focus		: 0
					,pagination  : false
				}
				,interval_banner: null
				, items_category: []
				, is_notice: false
				, is_not_today: true
			}
		}
		,computed: {
			item_list: function (){

				return this.items.filter((item) => {
					if(item.pdt_info){
						item.pdt_info = item.pdt_info.replaceAll('/API/', this.$server_url)
					}
					if(item.pdt_notice){
						item.pdt_notice = item.pdt_notice.replaceAll('/API/', this.$server_url)
					}

					if(item.pdt_img1){
						item.pdt_img = this.$pdt_img_url + item.pdt_img1
					}

					if(item.is_sold == 0 || (item.is_sold == 2 && item.pdt_stock > 0)){
						item.is_sale = true
					}

					return item
				})
			}
			, banner_main: function(){

				return this.items_banner_main.filter((item) => {
					if(item.file_path){
						item.banner_img = this.codes.banner_url + item.file_path
					}
					return item
				})
			}
			, banner_category: function(){

				return this.items_banner_category.filter((item) => {
					if(item.file_path){
						item.banner_img = this.codes.banner_url + item.file_path
					}
					return item
				})
			}
			,is_banner: function(){
				let is = true

				let location = window.location.href
				let domain = process.env.VUE_APP_DOMAIN

				if(domain.indexOf(location) > -1){
					is = true
				}

				return is
			}
		}
		,methods: {
			getData: async function(){
				this.$bus.$emit('on', true)
				try {
					const result = await this.Axios({
						method: 'get'
						, url: 'product/getMainProduct'
						, data: this.search
					})

					if (result.success) {
						let item = this.items
						this.items = item.concat(result.data.result)
						this.$set(this.search, 'total_count', result.data.tCnt)
						this.$storage.setQuery(this.search)
					} else {
						this.$bus.$emit('notify', {type: 'error', message: result.message})
					}
				}catch (e) {
					console.log(e)
				}finally {
					this.$bus.$emit('on', false)
					this.banner_item = this.items_banner_main[0]
				}
			}
			,toDetail(item){
				if(this.is_member){
					if(item.pdt_div == 'cucudas'){
						this.$storage.push({ name: 'ProductDetailCucudas', params: { idx: item.uid }, not_query: true})
					}else{
						this.$storage.push({ name: 'ProductDetail', params: { idx: item.uid }, not_query: true})
					}
				}else{
					alert('회원 전용입니다.')
				}
			}
			,clear(){
				this.$set(this, 'item', {})
				//this.setProgram(this.program)
			}
			,setProgram(program){
				this.$emit('onLoad', program)
			}
			,setNotify({ type, message}){
				this.$bus.$emit('notify', { type: type, message: message })
			}
			,setListType: function(list_type){
				this.list_type = list_type
				localStorage.setItem('list_type', list_type)
			}
			,setSort: function(){
				this.items = []
				this.search.page = 1
				this.getData()
			}
			,banner_prev: function(){
				clearTimeout(this.interval_banner)
				this.banner_index--
				if(this.banner_index < 0){
					this.banner_index = this.items_banner_main.length - 1
				}

				this.banner_item = this.items_banner_main[this.banner_index]
				this.interval_banner = setTimeout(() => {
					this.banner_next()
				}, 5000)
			}
			,banner_next: function(){

				clearTimeout(this.interval_banner)
				this.banner_index++
				if(this.banner_index >= this.items_banner_main.length){
					this.banner_index = 0
				}

				this.banner_item = this.items_banner_main[this.banner_index]
				this.interval_banner = setTimeout(() => {
					this.banner_next()
				}, 5000)
			}
			,toLink: function(banner){
				if(banner.banner_link){
					if(banner.is_out == '0'){
						document.location.href = banner.banner_link
					}else{
						window.open(banner.banner_link, 'banner')
					}
				}
			}
			,toList: function(){
				this.$emit('push', { name: 'ProductList'})
			}
			, toCategory: function(category){
				this.$emit('push', { name: 'ProductList', params: { category: category.banner_link}})
			}

			, closePop: function(){

				this.is_notice = false
				if(this.is_not_today){
					localStorage.setItem('is_not_today', this.$date.getToday('-'))
				}
			}
			, getPop: function(){
				let today = this.$date.getToday('-')
				let d = localStorage.getItem('is_not_today')
				if(today == d){
					this.is_notice = false
				}else{
					this.is_notice = true
				}
			}
		}
		,created: function(){
			this.$emit('onLoad', this.program)
			this.getPop()
			this.getData()
		}
	}
	
</script>

<style>

	ul.list {
		height: 100%
	}
	ul.list li {
		display: flex;
		border-bottom: 1px solid #ddd;
		padding: 10px 0;
	}

	ul.list .pdt-img {
		flex: 2;
		margin-right: 10px;
		padding: 5px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin-left: 5px;
		min-height: 130px;
	}

	ul.list .pdt-info {
		flex: 3;
		color: #333;
	}

	ul.grid {
		display: flex;
		flex-wrap: wrap;
	}
	ul.grid .pdt-info {
		text-align: left;
		color: #333;
	}
	ul.grid li { width: calc(50% - 10px); margin-bottom: 20px;}
	ul.grid li:nth-child(odd) { margin: 0px 5px 20px 5px;}
	ul.grid li:nth-child(even) { margin: 0px 5px 20px 5px;}

	ul.grid .pdt-img {
		width: 100%;
		background-color: white;
		text-align: center;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 5px;
		min-height: 150px;
	}

	ul.grid .pdt-img img { display: block; margin: auto; width: 100%; height: 100%}

	.pdt-img {
		overflow: hidden;

	}

	.pdt-img img {   }

	.box-main-banner {
		background-color: #eee;
	}

	.box-main-banner .no-banner {
		text-align: center;
		padding: 30px 0;
	}

	.box-main-banner .arrow-left{
		left: 10px;
		top: calc(50% - 12px);
	}
	.box-main-banner .arrow-right{
		right: 10px;
		top: calc(50% - 12px);
	}

	.ul-main-banner {
		border-bottom: 1px solid #ddd
	}
	.ul-main-banner li {
		min-height: 40px;
		text-align: center;
	}
	.banner-img { width:100%; padding: 90px}
	.banner-img img { width: 100%; max-height: 160px}

	.enter {
		transition: opacity 1s
	}
</style>
